/**
 * Timetastic
 * Features Single Quote component
 */
import React from "react"
import PropTypes from "prop-types"

// Styles
import "./featuresSingleQuote.scss"

const FeaturesSingleQuote = ({ quote }) => {
  return (
    <section className="c-section c-features-single-quotes">
      <div className="u-inner u-inner--l">
        <div className="c-features-single-quote">
          <div className="c-features-single-quote__body">
            <p>{quote.quote}</p>
          </div>
          <div className="c-features-single-quote__meta">
            <img
              loading="lazy"
              alt={quote.name}
              className="c-features-single-quote__avatar"
              src={quote.avatar}
            />
            <div className="c-features-single-quote__person">
              {quote.name}
              <br />
              {quote.company}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

FeaturesSingleQuote.propTypes = {
  quote: PropTypes.shape({
    avatar: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    quote: PropTypes.string.isRequired,
  }).isRequired,
}

export default FeaturesSingleQuote
